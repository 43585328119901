
export default {
  name: 'PrismicButtonLinkComponent',
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    theme: {
      type: String,
      required: false,
      default: 'soft-white',
    },
  },
  computed: {
    isVisible() {
      return this.data?.buttonText
    },
    computedTheme() {
      return this.theme || 'soft-white'
    },
  },
}
