import { render, staticRenderFns } from "./WithButton.vue?vue&type=template&id=16bbe8f4&"
import script from "./WithButton.vue?vue&type=script&lang=js&"
export * from "./WithButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PrismicButtonLinkComponent: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Prismic/ButtonLinkComponent/index.vue').default,ComponentsCardsWrapper: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Cards/Wrapper.vue').default})
