
import themeMixin from '~/mixins/theme'

export default {
  name: 'CardWrapper',
  mixins: [themeMixin],
  props: {
    cardData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    themeComputedClass() {
      return this.getThemeClass(this.cardData.theme || this.themeColor)
    },
    hasLinkType() {
      // link_type has 'Any' value when no value was setup
      return this.cardData?.buttonLink
        ? this.cardData.buttonLink.link_type !== 'Any'
        : false
    },
  },
}
