
export default {
  name: 'CardWithButton',
  props: {
    cardData: {
      type: Object,
      required: true,
    },
    hideDescriptionOnMobile: {
      type: Boolean,
      required: false,
      default: () => {
        return true
      },
    },
  },

  computed: {
    buttonTheme() {
      return this.cardData?.buttonTheme || 'soft-white'
    },
    title() {
      return this.cardData.title
    },
    description() {
      return this.cardData.description
    },
    noDescriptionReceived() {
      return !this.description || !this.description.trim().length
    },
    buttonLink() {
      return this.cardData.buttonLink
    },
    buttonText() {
      return this.cardData.buttonText
    },
  },
}
